import { Lot } from 'entities/chess';
import { NumberingFormatType } from 'entities/chess/model/types/lotSchema';

export const changeLotsNumbers = (
  lots: Lot[],
  numbering: string,
  formatType: NumberingFormatType
): Lot[] => {
  if (!numbering) {
    return clearNumbers(lots);
  }

  switch (formatType) {
    case NumberingFormatType.number:
      return numberFormat(lots, numbering);
    case NumberingFormatType.numberLetterNumber:
      return numberLetterNumberFormat(lots, numbering);
    case NumberingFormatType.numberNumber:
      return numberNumberFormat(lots, numbering, '-');
    case NumberingFormatType.numberSlashNumber:
      return numberNumberFormat(lots, numbering, '/');
    case NumberingFormatType.numberNumberLetter:
      return numberNumberLetterFormat(lots, numbering);
    case NumberingFormatType.letterNumberNumber:
      return letterNumberNumberFormat(lots, numbering);
    case NumberingFormatType.numberLetter:
      return numberLetterFormat(lots, numbering);
    case NumberingFormatType.numberPointNumber:
      return numberNumberFormat(lots, numbering, '.');
    case NumberingFormatType.letterNumber:
      return letterNumberFormat(lots, numbering);
    default:
      return lots; // Если формат не распознан, возвращаем исходные лоты.
  }
};

const clearNumbers = (lots: Lot[]): Lot[] => {
  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    return { ...lot, lotNumber: '' };
  });
};

const numberFormat = (lots: Lot[], numbering: string): Lot[] => {
  let currentNumber = parseInt(numbering, 10);

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    const updatedLot = { ...lot, lotNumber: currentNumber.toString() };
    currentNumber += 1;
    return updatedLot;
  });
};

const numberLetterNumberFormat = (lots: Lot[], numbering: string): Lot[] => {
  const match = numbering.match(/^(\d+)([а-яА-Яa-zA-Z])(\d+)$/);

  if (!match) throw new Error('Invalid format');

  const [_, prefix, letter, suffix] = match;

  let currentFloor = Math.min(...lots[0].positions.map((pos) => pos.floor));
  let currentPrefix = parseInt(prefix, 10);
  let currentSuffix = parseInt(suffix, 10);

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    const lotMinFloor = Math.min(...lot.positions.map((pos) => pos.floor));

    if (lotMinFloor > currentFloor) {
      currentFloor = lotMinFloor;
      currentPrefix++;
      currentSuffix = parseInt(suffix, 10); // сбросить currentSuffix на начальное значение
    }
    const changedLot = {
      ...lot,
      lotNumber: `${currentPrefix}${letter}${currentSuffix}`,
    };

    currentSuffix++;

    return changedLot;
  });
};

const numberNumberFormat = (
  lots: Lot[],
  numbering: string,
  delimiter: string
): Lot[] => {
  const [prefix, suffix] = numbering.split(delimiter);
  let currentPrefix = parseInt(prefix, 10);
  let currentSuffix = parseInt(suffix, 10);

  let currentFloor = Math.min(...lots[0].positions.map((pos) => pos.floor));

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    const lotMinFloor = Math.min(...lot.positions.map((pos) => pos.floor));

    // Если этаж у лота увеличился по сравнению с текущим
    if (lotMinFloor > currentFloor) {
      currentFloor = lotMinFloor;
      currentPrefix++;
      currentSuffix = parseInt(suffix, 10); // сбросить currentSuffix на начальное значение
    }

    const changedLot = {
      ...lot,
      lotNumber: `${currentPrefix}${delimiter}${currentSuffix}`,
    };
    currentSuffix++;

    return changedLot;
  });
};

const numberNumberLetterFormat = (lots: Lot[], numbering: string): Lot[] => {
  const [prefix, suffixWithLetter] = numbering.split('/');
  let currentPrefix = parseInt(prefix, 10);

  const suffixLetterMatch = suffixWithLetter.match(/(\d+)([а-яА-Яa-zA-Z])/);
  if (!suffixLetterMatch) throw new Error('Invalid format');

  let currentSuffix = parseInt(suffixLetterMatch[1], 10);
  const letter = suffixLetterMatch[2];

  let currentFloor = Math.min(...lots[0].positions.map((pos) => pos.floor));

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    const lotMinFloor = Math.min(...lot.positions.map((pos) => pos.floor));

    // Если этаж у лота увеличился по сравнению с текущим
    if (lotMinFloor > currentFloor) {
      currentFloor = lotMinFloor;
      currentPrefix++;
      currentSuffix = parseInt(suffixLetterMatch[1], 10);
    }

    const changedLot = {
      ...lot,
      lotNumber: `${currentPrefix}/${currentSuffix}${letter}`,
    };
    currentSuffix++;

    return changedLot;
  });
};

const letterNumberNumberFormat = (lots: Lot[], numbering: string): Lot[] => {
  const [prefixWithLetter, suffix] = numbering.split('-');

  const prefixLetterMatch = prefixWithLetter.match(/([а-яА-Яa-zA-Z])(\d+)/);
  if (!prefixLetterMatch) throw new Error('Invalid format');

  const letter = prefixLetterMatch[1];
  let currentPrefix = parseInt(prefixLetterMatch[2], 10);
  let currentSuffix = parseInt(suffix, 10);

  let currentFloor = Math.min(...lots[0].positions.map((pos) => pos.floor));

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    const lotMinFloor = Math.min(...lot.positions.map((pos) => pos.floor));

    // Если этаж у лота увеличился по сравнению с текущим
    if (lotMinFloor > currentFloor) {
      currentFloor = lotMinFloor;
      currentPrefix++;
      currentSuffix = parseInt(suffix, 10);
    }

    const changedLot = {
      ...lot,
      lotNumber: `${letter}${currentPrefix}-${currentSuffix}`,
    };
    currentSuffix++;

    return changedLot;
  });
};

const numberLetterFormat = (lots: Lot[], numbering: string): Lot[] => {
  const match = numbering.match(/(\d+)([а-яА-Яa-zA-Z])/);
  if (!match) throw new Error('Invalid format');

  let currentNumber = parseInt(match[1], 10);
  const letter = match[2];

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }

    const changedLot = {
      ...lot,
      lotNumber: `${currentNumber}${letter}`,
    };
    currentNumber++;

    return changedLot;
  });
};

const letterNumberFormat = (lots: Lot[], numbering: string): Lot[] => {
  const match = numbering.match(/^([а-яА-Яa-zA-Z])(\d+)$/);
  if (!match) throw new Error('Invalid format');

  const letter = match[1];
  let currentNumber = parseInt(match[2], 10);

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }

    const changedLot = {
      ...lot,
      lotNumber: `${letter}${currentNumber}`,
    };
    currentNumber++;

    return changedLot;
  });
};
