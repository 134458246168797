import { z } from 'zod';
import { emailValidator } from 'shared/helpers/emailValidator';
import { SelectOption } from 'shared/types';

// 1 - сотрудник nedvex
// 2 - администратор - полный доступ
// 4 — сотрудники имеющие доступ к выгрузке данных по api
// 5 - застройщик (админ)
// 6 - застройщик (сотрудник)

export enum UserRole {
  nedvexUser = 1,
  admin = 2,
  apiExporter = 4,
  developerAdmin = 5,
  developerEmployee = 6,
}

export const userRoleOptions: SelectOption[] = [
  {
    value: UserRole.admin,
    label: 'user.role.admin',
  },
  {
    value: UserRole.nedvexUser,
    label: 'user.role.nedvexUser',
  },
  {
    value: UserRole.developerAdmin,
    label: 'user.role.developerAdmin',
  },
  {
    value: UserRole.developerEmployee,
    label: 'user.role.developerEmployee',
  },
];

export type ProfileDto = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role_id: UserRole;
  token: string;
  developer_id: number | null;
  is_first_login: boolean;
};

export type Profile = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  roleId: UserRole;
  token: string;
  isFirstLogin: boolean;
  developerId?: number;
  isNedvexUserOrAdmin: boolean;
  isNedvexUserOrAdminOrApiExporter: boolean;
};

export const credentialsZod = z.object({
  email: emailValidator.required,
  password: z.string().min(1, 'Введите пароль').min(6, 'Минимум 6 символов'),
});

export type Credentials = z.infer<typeof credentialsZod>;

export interface ProfileSchema {
  profile?: Profile;
}

export const passwordRestorationZod = z.object({
  email: emailValidator.required,
});

export type PasswordRestoration = z.infer<typeof passwordRestorationZod>;
