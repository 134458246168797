import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'shared/types';
import { Col } from 'shared/ui/Col';
import { ExpandButton } from 'shared/ui/ExpandButton';
import { Row } from 'shared/ui/Row';
import { SelectChipField } from 'shared/ui/SelectChipField';
import { SelectField } from 'shared/ui/SelectField';
import { TextViewField } from 'shared/ui/TextViewField';
import { userTariffOptions } from '../model/consts';
import { User } from '../model/types/userSchema';

interface UserInfoFieldsProps {
  onToggleAdditionalData: (isShowAdditionalData: boolean) => void;
  isShowAdditionalData: boolean;
  userAgency?: SelectOption;
}

export const UserInfoFields: FC<UserInfoFieldsProps> = ({
  onToggleAdditionalData,
  isShowAdditionalData,
  userAgency,
}) => {
  const { t } = useTranslation();
  const form = useFormContext<User>();
  const {
    phone,
    lastName,
    position,
    email,
    facebook,
    instagram,
    ok,
    vk,
    youtube,
    agencyId,
    countDay,
    telegram,
    whatsapp,
  } = form.getValues();

  const isAdditionalData = Boolean(
    facebook || instagram || ok || vk || youtube || telegram || whatsapp
  );

  console.log(whatsapp);

  return (
    <Col spacing={8}>
      {phone && (
        <TextViewField
          variant="filled"
          name="phone"
          isPhone
          title={t('ui.phoneInput.defaultLabel')}
        />
      )}
      <Row
        equalsChildrenWidth
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 8, sm: 5 },
        }}
      >
        <TextViewField
          variant="filled"
          name="firstName"
          title={t('ui.common.firstName')}
        />
        {lastName && (
          <TextViewField
            variant="filled"
            name="lastName"
            title={t('ui.common.lastName')}
          />
        )}
      </Row>
      {(email || position) && (
        <Row
          equalsChildrenWidth
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 8, sm: 5 },
          }}
        >
          {email && (
            <TextViewField
              variant="filled"
              title={t('ui.common.email')}
              name="email"
            />
          )}
          {position && (
            <TextViewField
              variant="filled"
              title={t('user.form.label.position')}
              name="position"
            />
          )}
        </Row>
      )}
      {agencyId && userAgency && (
        <SelectField
          readOnly
          variant="filled"
          label={t('user.form.label.agency')}
          name="agencyId"
          options={[userAgency]}
        />
      )}
      {isAdditionalData && (
        <Col spacing={8}>
          <ExpandButton
            expanded={isShowAdditionalData}
            onClick={() => onToggleAdditionalData(!isShowAdditionalData)}
          >
            {t('user.form.label.additionalData')}
          </ExpandButton>
          {isShowAdditionalData && (
            <Col spacing={8}>
              {(vk || ok) && (
                <Row
                  equalsChildrenWidth
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 8, sm: 5 },
                  }}
                >
                  {vk && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.social.vk')}
                      name="vk"
                    />
                  )}
                  {ok && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.social.ok')}
                      name="ok"
                    />
                  )}
                </Row>
              )}
              {(telegram || whatsapp) && (
                <Row
                  equalsChildrenWidth
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 8, sm: 5 },
                  }}
                >
                  {telegram && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.telegram')}
                      name="telegram"
                    />
                  )}
                  {whatsapp && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.whatsapp')}
                      name="whatsapp"
                    />
                  )}
                </Row>
              )}
              {(facebook || instagram) && (
                <Row
                  equalsChildrenWidth
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 8, sm: 5 },
                  }}
                >
                  {facebook && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.social.facebook')}
                      name="facebook"
                    />
                  )}
                  {instagram && (
                    <TextViewField
                      variant="filled"
                      title={t('ui.common.social.instagram')}
                      name="instagram"
                    />
                  )}
                </Row>
              )}
              {youtube && (
                <TextViewField
                  variant="filled"
                  title={t('ui.common.social.youtube')}
                  name="youtube"
                  sx={{ width: { xs: '100%', sm: '49%' } }}
                />
              )}
            </Col>
          )}
        </Col>
      )}
      {countDay && (
        <SelectChipField
          name="countDay"
          chipOptions={userTariffOptions}
          label={t('user.form.label.subscriptionDaysLeft')}
          readonly
        />
      )}
    </Col>
  );
};
