import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography } from '@mui/material';
import { DeveloperUser, useDeleteDeveloperUserMutation } from 'entities/user';
import {
  getFormattedCountryPhone,
  getFormattedPhone,
} from 'shared/helpers/getFormattedPhone';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';

type DeveloperUserInfoCardProps = {
  user: DeveloperUser;
  onEditClick: (user: DeveloperUser) => void;
  onDelete?: () => void;
};

export const DeveloperUserInfoCard: FC<DeveloperUserInfoCardProps> = ({
  user,
  onEditClick,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [deleteDeveloperUser] = useDeleteDeveloperUserMutation();
  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: () => {
      deleteDeveloperUser(user);
      if (onDelete) {
        onDelete();
      }
    },
  });

  const handleEditClick = useCallback(() => {
    onEditClick(user);
  }, [onEditClick, user]);

  return (
    <Card sx={{ maxWidth: 606 }}>
      <CardContent
        sx={{
          paddingTop: 8,
          ':last-child': {
            paddingBottom: 8,
          },
        }}
      >
        <Col spacing={8}>
          <Col spacing={3}>
            <Typography variant={'headerXS'}>
              {user.lastName} {user.firstName}
            </Typography>
            <Typography variant={'accentM'} color={TextColor.Secondary}>
              {user.position}
            </Typography>
          </Col>

          <Col spacing={3}>
            <Typography variant={'accentM'} color={TextColor.Secondary}>
              {getFormattedPhone(user.phone)}, {user.email}
            </Typography>
            {user.whatsapp && (
              <Typography variant={'accentM'} color={TextColor.Secondary}>
                {t('ui.common.whatsapp')}:{' '}
                {getFormattedCountryPhone(user.whatsapp)}
              </Typography>
            )}
            {user.telegram && (
              <Typography variant={'accentM'} color={TextColor.Secondary}>
                {t('ui.common.telegram')}: {user.telegram}
              </Typography>
            )}
          </Col>
          {isShowRemoveConfirmation ? (
            <RemovalConfirmationCard
              titleEnding={t('user.card.deleteEnding')}
              onRemoveClick={confirmRemoveAction}
              onCancelClick={hideRemoveConfirmation}
            />
          ) : (
            <Row spacing={5}>
              <SmallSquareButton onClick={handleEditClick} icon="edit" />
              <SmallSquareButton onClick={showRemoveConfirmation} />
            </Row>
          )}
        </Col>
      </CardContent>
    </Card>
  );
};
