import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRoute } from 'shared/const';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { DocumentViewField } from 'shared/ui/DocumentViewField';
import { Developer } from '../../../model/types/developerSchema';

type DocumentsCardProps = {
  developer: Developer;
  onEditClick?: () => void;
};

export const DocumentsCard: FC<DocumentsCardProps> = ({ developer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CardLayout
      width={344}
      variant="secondary"
      paddingY={32}
      header={{
        title: t('developer.edit.tabLabel.documents'),
      }}
      footer={{
        cancelLabel: t('ui.common.change'),
        onCancelClick: () =>
          navigate(getRoute.EditDeveloperDocumentsTab(developer.id)),
      }}
    >
      <Col spacing={5}>
        {developer.documentNalogHash && (
          <DocumentViewField
            key={1}
            hash={developer.documentNalogHash}
            description={t('developer.form.label.documentNalog')}
          />
        )}
        {developer.documentUstavHash && (
          <DocumentViewField
            key={2}
            hash={developer.documentUstavHash}
            description={t('developer.form.label.documentUstav')}
          />
        )}
        {developer.documentDirectorHash && (
          <DocumentViewField
            key={3}
            hash={developer.documentDirectorHash}
            description={t('developer.form.label.documentDirector')}
          />
        )}
        {developer.documentGenDirectorHash && (
          <DocumentViewField
            key={4}
            hash={developer.documentGenDirectorHash}
            description={t('developer.form.label.documentGenDirector')}
          />
        )}
        {developer.documentPermissionsApproveHash && (
          <DocumentViewField
            key={5}
            hash={developer.documentPermissionsApproveHash}
            description={t('developer.form.label.documentPermissionsApprove')}
          />
        )}
      </Col>
    </CardLayout>
  );
};
