import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Divider, Typography } from '@mui/material';
// eslint-disable-next-line boundaries/element-types
import { SalesOffice } from 'entities/salesOffice';
import {
  getFormattedCountryPhone,
  getFormattedPhone,
} from 'shared/helpers/getFormattedPhone';
import { TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { ExpandButton } from 'shared/ui/ExpandButton';
import { ProfileIcon, TelegramIcon, WhatsAppIcon } from 'shared/ui/Icons';
import { Row } from 'shared/ui/Row';

type SalesOfficeCardProps = {
  salesOffice: Partial<SalesOffice>;
};
// TODO: перенести в entities/salesOffice
export const SalesOfficeCard: FC<SalesOfficeCardProps> = ({ salesOffice }) => {
  const { t } = useTranslation();
  const [isShowAllEmployees, setIsShowAllEmployees] = useState(false);

  const displayedEmployees = isShowAllEmployees
    ? salesOffice.employees
    : salesOffice.employees?.slice(0, 3);

  return (
    <Card sx={{ p: 8 }}>
      <Col spacing={8}>
        <Col spacing={3}>
          <Typography variant="headerXS">
            {t('object.card.salesOffice')}
          </Typography>
          <Typography variant="accentM" color={TextColor.Secondary}>
            {salesOffice?.address}
          </Typography>
        </Col>
        <Typography variant="accentM" color={TextColor.Secondary}>
          {`${getFormattedPhone(salesOffice.phone || '')}${
            salesOffice.phone && salesOffice.email ? ',' : ''
          } ${salesOffice.email || ''}`}
        </Typography>
        {salesOffice.employees?.length ? <Divider /> : null}
        {salesOffice.employees?.length ? (
          <Col spacing={6}>
            <Typography variant="headerXS">
              {t('salesOffice.card.employees')}
            </Typography>
            {displayedEmployees?.map((employee) => (
              <Row spacing={5} key={employee.employeeId}>
                <ProfileIcon
                  sx={{
                    fontSize: '32px',
                  }}
                />
                <Col spacing={3}>
                  <Typography variant="headerXS">
                    {`${employee.lastName} ${employee.firstName}`}
                  </Typography>
                  {employee.phone && (
                    <Typography variant="accentM" color={TextColor.Secondary}>
                      {getFormattedPhone(employee.phone)}
                    </Typography>
                  )}
                  {employee.whatsapp && (
                    <Typography
                      variant="accentM"
                      color={TextColor.Secondary}
                      sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
                    >
                      <WhatsAppIcon />{' '}
                      {getFormattedCountryPhone(employee.whatsapp)}
                    </Typography>
                  )}
                  {employee.telegram && (
                    <Typography
                      variant="accentM"
                      color={TextColor.Secondary}
                      sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
                    >
                      <TelegramIcon />
                      {employee.telegram}
                    </Typography>
                  )}
                </Col>
              </Row>
            ))}
            {salesOffice.employees && salesOffice.employees.length > 3 && (
              <ExpandButton
                expanded={isShowAllEmployees}
                onClick={() => setIsShowAllEmployees(!isShowAllEmployees)}
                hideArrow
              >
                {isShowAllEmployees
                  ? t('ui.textViewField.hide')
                  : t('ui.textViewField.showMore')}
              </ExpandButton>
            )}
          </Col>
        ) : null}
      </Col>
    </Card>
  );
};
