import { FC, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { skipToken } from '@reduxjs/toolkit/query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Agency } from 'entities/agency';
import { ServerErrorMessage } from 'entities/developer';
import {
  UserEditForm,
  UserInfoFields,
  useGetUserQuery,
  userZod,
  User,
  useUpdateUserMutation,
  userActions,
} from 'entities/user';
import { getRoute } from 'shared/const';
import { useAppDispatch, useAppSelector } from 'shared/hooks/hooks';
import { CardLayout } from 'shared/ui/CardLayout';
import { ResultCard } from 'shared/ui/ResultCard';
import { RectSkeleton } from 'shared/ui/Skeleton/RectSkeleton';

export const AgencyUserDetailsCard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams<{
    userId: string;
  }>();
  const { id: agencyId, name: agencyName } = useOutletContext<Agency>();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isShowAdditionalData, setIsShowAdditionalData] =
    useState<boolean>(false);
  const userAgency = useAppSelector((state) => state.user.userAgency);
  const dispatch = useAppDispatch();

  const { data: user, isLoading: isUserLoading } = useGetUserQuery(
    userId ?? skipToken
  );
  const [isUserUpdatingError, setIsUserUpdatingError] = useState(false);

  const [
    updateUser,
    { isLoading: isUserUpdating, isSuccess: isUserSuccessfullyUpdated },
  ] = useUpdateUserMutation();

  const form = useForm<User>({
    resolver: zodResolver(userZod),
    mode: 'onSubmit',
    defaultValues: {
      id: user?.id || undefined,
      phone: user?.phone || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      position: user?.position || '',
      agencyId: user?.agencyId || undefined,
      isAdmin: user?.isAdmin || false,
      facebook: user?.facebook || '',
      instagram: user?.instagram || '',
      vk: user?.vk || '',
      ok: user?.ok || '',
      youtube: user?.youtube || '',
      tariff: user?.tariff || null,
      telegram: user?.telegram || '',
      whatsapp: user?.whatsapp || '',
    },
  });

  useEffect(() => {
    dispatch(
      userActions.updateUserAgency({
        label: agencyName,
        value: agencyId,
      })
    );
  }, [agencyId, agencyName, dispatch]);

  useEffect(() => {
    if (user) {
      form.reset(user);
    }
  }, [form, user]);

  const handleUpdateUser = async (user: User) => {
    try {
      await updateUser(user).unwrap();
    } catch (error) {
      console.error(error);
      switch (error) {
        case 'ERROR_EMAIL_ALREADY_USED':
          form.setError('email', {
            message: ServerErrorMessage.ownerEmailInUse,
          });
          break;
        case 'ERROR_PHONE_ALREADY_USED':
          form.setError('phone', {
            message: ServerErrorMessage.ownerPhoneInUse,
          });
          break;
        default:
          setIsUserUpdatingError(true);
          break;
      }
    }
  };

  if (isUserSuccessfullyUpdated || isUserUpdatingError) {
    return (
      <ResultCard
        isSuccess={isUserSuccessfullyUpdated}
        successProps={{
          primaryButton: {
            navigateTo: getRoute.Users(),
          },
        }}
        errorProps={{
          primaryButton: {
            label: t('ui.common.returnToMain'),
            navigateTo: getRoute.Users(),
          },
        }}
      />
    );
  }

  if (isUserLoading || !user) {
    return <RectSkeleton maxWidth={648} height={400} sx={{ m: 'auto' }} />;
  }

  return (
    <CardLayout
      header={{ title: t('user.details.card.title') }}
      footer={{
        onOkClick: isEditMode
          ? form.handleSubmit(handleUpdateUser)
          : () => setIsEditMode(true),
        onCancelClick: isEditMode
          ? () => setIsEditMode(false)
          : () => navigate(getRoute.AgencyUsers(Number(agencyId))),
        okLabel: isEditMode ? t('ui.common.save') : t('ui.common.edit'),
        cancelLabel: isEditMode ? t('ui.common.cancel') : t('ui.common.close'),
        okButtonLoading: isUserUpdating,
      }}
      maxWidth={648}
      marginAuto
      paddingY={24}
    >
      <FormProvider {...form}>
        {isEditMode ? (
          <UserEditForm
            isShowAdditionalData={isShowAdditionalData}
            onToggleAdditionalData={setIsShowAdditionalData}
            userAgency={userAgency}
          />
        ) : (
          <UserInfoFields
            isShowAdditionalData={isShowAdditionalData}
            onToggleAdditionalData={setIsShowAdditionalData}
            userAgency={userAgency}
          />
        )}
      </FormProvider>
    </CardLayout>
  );
};
