export function getFormattedPhone(phone: string) {
  const formattedPhoneNumber = `+${phone.substring(0, 1)} (${phone.substring(
    1,
    4
  )}) ${phone.substring(4, 7)}-${phone.substring(7, 9)}-${phone.substring(
    9,
    11
  )}`;

  return formattedPhoneNumber;
}

export function getFormattedCountryPhone(phone: string): string {
  let countryCode = '';
  if (phone.startsWith('995')) {
    countryCode = '995';
  } else if (phone.startsWith('62')) {
    countryCode = '62';
  } else if (phone.startsWith('7')) {
    countryCode = '7';
  }

  const localNumber = phone.substring(countryCode.length);

  if (countryCode === '7') {
    return `+${countryCode} (${localNumber.substring(
      0,
      3
    )}) ${localNumber.substring(3, 6)}-${localNumber.substring(
      6,
      8
    )}-${localNumber.substring(8)}`;
  }

  if (countryCode === '62') {
    return `+${countryCode} ${localNumber.substring(
      0,
      3
    )}-${localNumber.substring(3, 7)}-${localNumber.substring(7)}`;
  }

  if (countryCode === '995') {
    return `+${countryCode} ${localNumber.substring(
      0,
      3
    )} ${localNumber.substring(3, 5)} ${localNumber.substring(
      5,
      7
    )} ${localNumber.substring(7)}`;
  }

  return phone;
}
