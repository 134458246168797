import { z } from 'zod';
// eslint-disable-next-line boundaries/element-types
import { UserRole } from 'entities/profile';
import { emailValidator } from 'shared/helpers/emailValidator';
import { phoneValidator } from 'shared/helpers/phoneValidator';
import { telegramValidator } from 'shared/helpers/telegramValidator';

export type DeveloperUserDto = {
  id?: number;
  first_name: string;
  last_name: string | null;
  email: string;
  role_id: UserRole;
  phone: number;
  position: string;
  developer_id?: number;
  whatsapp: string | null;
  telegram: string | null;
};

export const developerUserZod = z.object({
  id: z.number(),
  developerId: z.number().optional(),
  firstName: z.string().min(1, 'ui.common.error.firstName'),
  lastName: z.string().optional(),
  position: z.string().min(1, 'user.form.error.position'),
  whatsapp: z.string().nullable(),
  telegram: telegramValidator.nullable(),
  phone: phoneValidator.required,
  email: emailValidator.required,
  roleId: z.number(),
});

export const createDeveloperUserZod = developerUserZod.omit({
  id: true,
});

export type DeveloperUser = z.infer<typeof developerUserZod>;
export type NewDeveloperUser = Omit<DeveloperUser, 'id'>;
