// eslint-disable-next-line boundaries/element-types
import {
  SalesOffice,
  SaveSalesOfficeDto,
  mapDtoFromSalesOffice,
} from 'entities/salesOffice';
import { encodeHtmlEntities } from 'shared/helpers/encodeHtmlEntities';
import {
  DeveloperObject,
  EditDeveloperObject,
  ObjectDto,
  SaveObjectDto,
} from '../model/types/objectSchema';

export const mapObjectFromDto = ({
  id,
  zhk_name,
  published,
  status,
  region_id,
  developer_id,
  checkout,
  house_territory,
  house_parking,
  parking_underground,
  parking_multilevel,
  children_area,
  sport_area,
  spa,
  fitness,
  pool,
  kindergarten,
  distance_sea,
  service_cost,
  description,
  address,
  latitude,
  longitude,
  class_id,
  gas_surcharge_price,
  building_material_id,
  concierge,
  elevator,
  tennis_court,
  recreation_zone,
  fountain,
  garden,
  helicopter_landing_area,
  commercial_property,
  cctv_surveillance,
  parking_type_roofed,
  official_site,
  commission,
  ceiling_height,
  docs_surcharge_price,
  communications_surcharge_price,
  commission_note,
  type_id,
  house_road_id,
  sauna,
  restaurant,
  billiards,
  kpp,
  landscape_decore,
  is_children_room,
  is_conference_hall,
  is_wellness_building,
  is_beach,
  is_walking_area,
  is_operated_roof,
  building_type,
  housing_gas,
  housing_sewage,
  housing_heating,
  housing_water_supply,
  housing_electricity,
  sales_offices,
  contractors,
  main_photo_hash,
  is_garage,
  is_landscape_park,
  is_acs_system,
  is_management_company,
  new: isNew,
  nedvex,
  building_create_manager,
  building_update_manager,
  date_create,
  date_update,
  version,
  area_id,
  city_id,
}: ObjectDto): DeveloperObject => {
  return {
    id,
    name: zhk_name,
    published: published ? 1 : 0,
    address,
    latitude,
    longitude,
    site: official_site,
    communicationsPrice: communications_surcharge_price?.toString() || '',
    developerId: developer_id || undefined,
    documentsPrice: docs_surcharge_price?.toString() || '',
    gasPrice: gas_surcharge_price?.toString() || '',
    objectType: type_id?.toString() || '',
    houseType: building_type ? building_type.id?.toString() : '',
    objectDescription: description,
    federalLow: checkout,
    houseClass: class_id,
    microdistrict: region_id?.toString() || '',
    saleStatus: status,
    seaDistance: distance_sea?.toString() || '',
    isNewObject: !!isNew,
    ceilingHeight: ceiling_height?.toString() || '',
    maintenancePrice: service_cost?.toString() || '',
    material: building_material_id?.toString() || '',
    commission,
    territory: house_territory,
    otherData: commission_note,
    entrances: house_road_id?.toString() || '',
    gas: housing_gas ? housing_gas.id?.toString() : '',
    canalization: housing_sewage?.id?.toString() || '',
    electricity: housing_electricity?.id?.toString() || '',
    waterSupply: housing_water_supply?.id?.toString() || '',
    heating: housing_heating?.id?.toString() || '',
    mainPhotoHash: main_photo_hash?.toString(),
    buildingCreateManager: building_create_manager,
    buildingUpdateManager: building_update_manager,
    dateCreate: date_create,
    dateUpdate: date_update,
    version,
    area: area_id?.toString() || '',
    city: city_id?.toString() || '',

    nedvex,

    contractors: contractors
      ? contractors.map((contractor) => {
          return {
            id: contractor.id,
            contractorName: contractor.name,
            inn: contractor.inn || undefined,
          };
        })
      : [],

    salesOffices: sales_offices
      ? sales_offices.map((salesOffice) => ({
          id: salesOffice.id,
          phone: String(salesOffice.phone || ''),
          email: salesOffice.email || '',
          address: salesOffice.address || '',
          schedules: (salesOffice.sales_office_schedules || []).map(
            (schedule) => ({
              workingDays: schedule.schedule || '',
              workingHours: `${schedule.time_from?.substring(0, 5) || ''} - ${
                schedule.time_to?.substring(0, 5) || ''
              }`,
            })
          ),
          employees: (salesOffice.users || []).map((user) => ({
            employeeId: user.id,
            firstName: user.first_name || '',
            lastName: user.last_name || '',
            email: user.email || '',
            phone: user.phone || '',
            telegram: user.telegram || '',
            whatsapp: user.whatsapp || '',
            order: user.order,
            position: user.position || '',
          })),
        }))
      : [],

    parking: [
      house_parking ? 'house_parking' : '',
      parking_multilevel ? 'parking_multilevel' : '',
      parking_underground ? 'parking_underground' : '',
      parking_type_roofed ? 'parking_type_roofed' : '',
    ].filter((value) => value !== ''),

    advantages: [
      spa ? 'spa' : '',
      pool ? 'pool' : '',
      billiards ? 'billiards' : '',
      helicopter_landing_area ? 'helicopter_landing_area' : '',
      cctv_surveillance ? 'cctv_surveillance' : '',
      is_children_room ? 'is_children_room' : '',
      children_area ? 'children_area' : '',
      kindergarten ? 'kindergarten' : '',
      recreation_zone ? 'recreation_zone' : '',
      commercial_property ? 'commercial_property' : '',
      concierge ? 'concierge' : '',
      is_conference_hall ? 'is_conference_hall' : '',
      kpp ? 'kpp' : '',
      landscape_decore ? 'landscape_decore' : '',
      elevator ? 'elevator' : '',
      is_wellness_building ? 'is_wellness_building' : '',
      is_beach ? 'is_beach' : '',
      is_walking_area ? 'is_walking_area' : '',
      restaurant ? 'restaurant' : '',
      garden ? 'garden' : '',
      sauna ? 'sauna' : '',
      sport_area ? 'sport_area' : '',
      tennis_court ? 'tennis_court' : '',
      fitness ? 'fitness' : '',
      fountain ? 'fountain' : '',
      is_garage ? 'is_garage' : '',
      is_landscape_park ? 'is_landscape_park' : '',
      is_acs_system ? 'is_acs_system' : '',
      is_management_company ? 'is_management_company' : '',
      is_operated_roof ? 'is_operated_roof' : '',
    ].filter((value) => value !== ''),
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-empty-pattern,
export const mapDtoFromObject = ({
  id,
  name,
  address,
  site,
  communicationsPrice,
  developerId,
  documentsPrice,
  gasPrice,
  objectType,
  objectDescription,
  federalLow,
  houseClass,
  microdistrict,
  saleStatus,
  seaDistance,
  maintenancePrice,
  parking,
  houseType,
  material,
  heating,
  electricity,
  advantages,
  canalization,
  entrances,
  contractors,
  gas,
  waterSupply,
  commission,
  territory,
  otherData,
  salesOffices,
  mainPhotoHash,
  published,
  ceilingHeight,
  latitude,
  longitude,
  isNewObject,
  nedvex,
  area,
  city,
}: EditDeveloperObject): SaveObjectDto => {
  const dto: SaveObjectDto = {
    zhk_name: name,
    published,
    main_photo_hash: mainPhotoHash || null,
    address,
    latitude,
    longitude,
    official_site: site || '',
    communications_surcharge_price: communicationsPrice
      ? Number(communicationsPrice)
      : null,
    developer_id: developerId || null,
    docs_surcharge_price: documentsPrice ? Number(documentsPrice) : null,
    gas_surcharge_price: gasPrice ? Number(gasPrice) : null,
    type_id: objectType ? Number(objectType) : null,
    description: encodeHtmlEntities(objectDescription),
    checkout: federalLow || '',
    class_id: houseClass,
    region_id: microdistrict ? Number(microdistrict) : null,
    status: saleStatus,
    distance_sea: seaDistance ? Number(seaDistance) : null,
    ceiling_height: ceilingHeight ? Number(ceilingHeight) : null,
    service_cost: maintenancePrice ? Number(maintenancePrice) : null,
    building_material_id: material ? Number(material) : null,
    commission: commission || '',
    house_road_id: entrances ? Number(entrances) : null,
    housing_electricity_id: electricity ? Number(electricity) : null,
    housing_water_supply_id: waterSupply ? Number(waterSupply) : null,
    housing_heating_id: heating ? Number(heating) : null,
    housing_sewage_id: canalization ? Number(canalization) : null,
    housing_gas_id: gas ? Number(gas) : null,
    building_type_id: houseType ? Number(houseType) : null,
    commission_note: otherData || '',
    house_territory: territory || '',
    contractors: contractors
      ? contractors.filter(Boolean).map((contractor) => Number(contractor))
      : [],
    sales_offices: processSalesOffices(salesOffices as SaveSalesOffices),
    surcharge_gas: Boolean(maintenancePrice),
    surcharge_communications: Boolean(communicationsPrice),
    surcharge_docs: Boolean(documentsPrice),
    new: isNewObject ? 1 : 0,
    city_id: Number(city),
    area_id: Number(area),

    // mock data
    children_garden: false,
    surcharge_balcony: false,
    surcharge_trash: false,

    nedvex,

    // parking
    house_parking: parking?.includes('house_parking') || false,
    parking_multilevel: parking?.includes('parking_multilevel') || false,
    parking_underground: parking?.includes('parking_underground') || false,
    parking_type_roofed: parking?.includes('parking_type_roofed') || false,

    // advantages

    spa: advantages?.includes('spa') || false,
    pool: advantages?.includes('pool') || false,
    billiards: (advantages?.includes('billiards') && 1) || 0,
    helicopter_landing_area:
      advantages?.includes('helicopter_landing_area') || false,
    cctv_surveillance: advantages?.includes('cctv_surveillance') || false,
    is_children_room: (advantages?.includes('is_children_room') && 1) || 0,
    children_area: advantages?.includes('children_area') || false,
    kindergarten: advantages?.includes('kindergarten') || false,
    recreation_zone: advantages?.includes('recreation_zone') || false,
    commercial_property: advantages?.includes('commercial_property') || false,
    concierge: advantages?.includes('concierge') || false,
    is_conference_hall: (advantages?.includes('is_conference_hall') && 1) || 0,
    kpp: (advantages?.includes('kpp') && 1) || 0,
    landscape_decore: (advantages?.includes('landscape_decore') && 1) || 0,
    elevator: advantages?.includes('elevator') || false,
    is_wellness_building:
      (advantages?.includes('is_wellness_building') && 1) || 0,
    is_beach: (advantages?.includes('is_beach') && 1) || 0,
    is_walking_area: (advantages?.includes('is_walking_area') && 1) || 0,
    restaurant: (advantages?.includes('restaurant') && 1) || 0,
    garden: advantages?.includes('garden') || false,
    sauna: (advantages?.includes('sauna') && 1) || 0,
    sport_area: advantages?.includes('sport_area') || false,
    tennis_court: advantages?.includes('tennis_court') || false,
    fitness: advantages?.includes('fitness') || false,
    fountain: advantages?.includes('fountain') || false,
    is_operated_roof: (advantages?.includes('is_operated_roof') && 1) || 0,
    is_acs_system: advantages?.includes('is_acs_system') || false,
    is_garage: advantages?.includes('is_garage') || false,
    is_landscape_park: advantages?.includes('is_landscape_park') || false,
    is_management_company:
      advantages?.includes('is_management_company') || false,
  };

  if (id) dto.id = id;

  return dto;
};

type SaveSalesOffices = number[] | SalesOffice[];

function processSalesOffices(
  salesOffices: SaveSalesOffices
): SaveSalesOfficeDto[] | number[] {
  if (typeof salesOffices[0] === 'number') {
    return salesOffices as number[];
  } else {
    return (salesOffices as SalesOffice[]).map((salesOffice) =>
      mapDtoFromSalesOffice(salesOffice)
    );
  }
}
