import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import {
  advantagesOptions,
  entrancesOptions,
  federalLowOptions,
  houseClassOptions,
  materialOptions,
  parkingOptions,
  territoryOptions,
  electricityOptions,
  waterSupplyOptions,
  heatingOptions,
  canalizationOptions,
  gasOptions,
} from 'entities/object';
import { CheckboxField } from 'shared/ui/CheckboxField';
import { Col } from 'shared/ui/Col';
import { NumericField } from 'shared/ui/NumericField';
import { Row } from 'shared/ui/Row';
import { SelectField } from 'shared/ui/SelectField';
import { SelectWithChips } from 'shared/ui/SelectWithChips';
import { StyledTextField } from 'shared/ui/StyledTextField';

export const ObjectCharacteristicsEditForm: FC = () => {
  const { t } = useTranslation();

  return (
    <Col spacing={8}>
      <Col spacing={5}>
        <Typography variant="headerXS">
          {t('object.form.label.houseDocumentation')}
        </Typography>
        <Row spacing={5} equalsChildrenWidth>
          <SelectField
            options={federalLowOptions}
            label={t('object.form.label.federalLow')}
            name={'federalLow'}
          />
        </Row>
      </Col>
      <StyledTextField
        label={t('object.form.label.commission')}
        name="commission"
        multiline
        maxLength={255}
        showCharacterHintAtRemaining={50}
      />
      <Col spacing={5}>
        <Typography variant="headerXS">
          {t('object.form.label.houseCharacteristics')}
        </Typography>
        <SelectField
          options={houseClassOptions}
          label={t('object.form.label.houseClass')}
          required
          name={'houseClass'}
        />
      </Col>
      <Row spacing={5} equalsChildrenWidth>
        <SelectField
          options={materialOptions}
          label={t('object.form.label.material')}
          required
          name={'material'}
        />
        <SelectField
          options={territoryOptions}
          label={t('object.form.label.territory')}
          name={'territory'}
        />
      </Row>
      <SelectWithChips
        label={t('object.form.label.parking')}
        name="parking"
        options={parkingOptions}
      />
      <SelectWithChips
        label={t('object.form.label.advantages')}
        name="advantages"
        options={advantagesOptions}
      />
      <SelectField
        options={gasOptions}
        label={t('object.form.label.gas')}
        required
        name={'gas'}
      />
      <SelectField
        options={canalizationOptions}
        label={t('object.form.label.canalization')}
        required
        name={'canalization'}
      />
      <SelectField
        options={heatingOptions}
        label={t('object.form.label.heating')}
        required
        name={'heating'}
      />
      <SelectField
        options={waterSupplyOptions}
        label={t('object.form.label.waterSupply')}
        required
        name={'waterSupply'}
      />
      <SelectField
        options={electricityOptions}
        label={t('object.form.label.electricity')}
        required
        name={'electricity'}
      />
      <SelectField
        options={entrancesOptions}
        label={t('object.form.label.entrances')}
        name={'entrances'}
      />
      <Col spacing={5}>
        <Typography variant="headerXS">
          {t('object.form.label.additionalCharacteristics')}
        </Typography>
        <CheckboxField
          name="isNewObject"
          label={t('object.form.label.newObject')}
        />
      </Col>
      <NumericField
        label={t('object.form.label.ceilingHeight')}
        name="ceilingHeight"
        valueIsNumericString
        maxLength={4}
        showCharacterHintAtRemaining={1}
        allowNegative={false}
      />
      <NumericField
        label={t('object.form.label.maintenancePrice')}
        name="maintenancePrice"
        valueIsNumericString
        thousandSeparator={' '}
        maxLength={7}
        showCharacterHintAtRemaining={2}
        allowNegative={false}
        decimalScale={0}
      />
      <NumericField
        label={t('object.form.label.gasPrice')}
        name="gasPrice"
        valueIsNumericString
        thousandSeparator={' '}
        maxLength={7}
        showCharacterHintAtRemaining={2}
        allowNegative={false}
        decimalScale={0}
      />
      <NumericField
        label={t('object.form.label.communicationsPrice')}
        name="communicationsPrice"
        valueIsNumericString
        thousandSeparator={' '}
        maxLength={7}
        showCharacterHintAtRemaining={2}
        allowNegative={false}
        decimalScale={0}
      />
      <NumericField
        label={t('object.form.label.documentsPrice')}
        name="documentsPrice"
        valueIsNumericString
        thousandSeparator={' '}
        maxLength={7}
        showCharacterHintAtRemaining={2}
        allowNegative={false}
        decimalScale={0}
      />
      <StyledTextField
        label={t('object.form.label.otherData')}
        name="otherData"
        maxLength={255}
        showCharacterHintAtRemaining={50}
      />
    </Col>
  );
};
