import { z } from 'zod';
import { LotStatus, optionalStatuses } from 'shared/selectOptions/chessOptions';

export const lotZod = z
  .object({
    id: z.number(),
    lotNumber: z.string().optional(),
    disabled: z.boolean(),
    status: z.string().min(1, 'Выберите статус'),
    lotType: z.string().min(1, 'Выберите тип'),
    actualSaleDate: z.string().optional(),
    numberOfFloors: z.string().optional(),
    numberOfRooms: z.string().optional(),
    landArea: z.string().optional(),
    totalArea: z.string().min(1, 'Введите значение'),
    kitchenArea: z.string().optional(),
    balconyArea: z.string().optional(),
    decoration: z.string().optional(),
    windowView: z.string().optional(),
    registration: z.array(z.string()).optional(),
    buyingOptions: z.array(z.string()).optional(),
    installmentTerms: z.string(),
    panorama: z.string().optional(),
    promotion: z.string().optional(),
    totalPrice: z.string().min(1, 'Введите значение'),
    pricePerMeter: z.string().min(1, 'Введите значение'),

    type: z.enum(['number', 'flat', 'head']),
    // entranceId: z.string(),

    // lotVisibility: z.string().array(),
    positions: z
      .object({
        floor: z.number(),
        riser: z.number(),
      })
      .array(),
    row: z.number(),
    colspan: z.number(),
    rowspan: z.number(),
    step: z.number().optional(),
    headId: z.number(),
    // поля нужные для сохранения связи с планировками
    poligon_id: z.number().optional(),
    number_id: z.number().optional(),
    plan_id: z.number().optional(),
  })
  .refine(
    (values) => {
      if (
        values.buyingOptions?.length &&
        values.buyingOptions.includes('installments') &&
        !values.installmentTerms
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'object.form.error.installmentTerms',
      path: ['installmentTerms'],
    }
  );

export const editLotZod = z
  .object({
    lotNumber: z.string().optional(),
    lotNumberingFormat: z.string().optional(),
    status: z.string().min(1, 'chess.dialog.error.status'),
    lotType: z.string().optional(),
    actualSaleDate: z.string().optional(),
    numberOfFloors: z.string().optional(),
    numberOfRooms: z.string().optional(),
    landArea: z.string().optional(),
    totalArea: z.string().optional(),
    kitchenArea: z.string().optional(),
    balconyArea: z.string().optional(),
    decoration: z.string().optional(),
    windowView: z.string().optional(),
    registration: z.array(z.string()),
    buyingOptions: z.array(z.string()),
    installmentTerms: z.string().optional(),
    panorama: z.string().optional(),
    promotion: z.string().optional(),
    totalPrice: z.string().optional(),
    pricePerMeter: z.string().optional(),
    type: z.enum(['number', 'flat', 'head']),
  })
  .superRefine((values, ctx) => {
    if (values.status === LotStatus.SoldStatus && !values.actualSaleDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'chess.dialog.error.date',
        path: ['actualSaleDate'],
      });
    }
    if (
      values.buyingOptions?.includes('installments') &&
      !values.installmentTerms
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'object.form.error.installmentTerms',
        path: ['installmentTerms'],
      });
    }

    if (!optionalStatuses.includes(values.status as LotStatus)) {
      if (!values.totalArea) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'ui.common.errorEnterValue',
          path: ['totalArea'],
        });
      }
      if (!values.pricePerMeter) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'ui.common.errorEnterValue',
          path: ['pricePerMeter'],
        });
      }
      if (!values.totalPrice) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'ui.common.errorEnterValue',
          path: ['totalPrice'],
        });
      }
      if (!values.lotType) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'chess.dialog.error.lotType',
          path: ['lotType'],
        });
      }
      if (!values.registration.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'ui.common.error.selectValue',
          path: ['registration'],
        });
      }
    }
  });

export const editMultipleLotZod = z.object({
  lotNumber: z.string().optional(),
  lotNumberingFormat: z.string().optional(),
  status: z.string().optional(),
  lotType: z.string().optional(),
  actualSaleDate: z.string().optional(),
  numberOfFloors: z.string().optional(),
  numberOfRooms: z.string().optional(),
  landArea: z.string().optional(),
  totalArea: z.string().optional(),
  kitchenArea: z.string().optional(),
  balconyArea: z.string().optional(),
  decoration: z.string().optional(),
  windowView: z.string().optional(),
  registration: z.array(z.string()).optional(),
  buyingOptions: z.array(z.string()).optional(),
  installmentTerms: z.string().optional(),
  panorama: z.string().optional(),
  promotion: z.string().optional(),
  totalPrice: z.string().optional(),
  pricePerMeter: z.string().optional(),
  type: z.enum(['number', 'flat', 'head']),
});

export type Lot = z.infer<typeof lotZod> & { lotNumberingFormat: string };

export type NewChess = {
  entranceId: number;
  floorsCount: number;
  risersCount: number;
};

export type UpdatedChess = {
  entranceId: number;
  lots: (Lot | NewLot)[];
  risers: (Lot | NewLot)[];
  floors: (Lot | NewLot)[];
};

export type Chess = {
  id: number;
  name: string;
  floorAmount: number;
  riserAmount: number;
  buildingId: number;
  createDate: string;
  updateDate: string;
  managerUpdate?: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  lots: Lot[];
  risers: Lot[];
  floors: Lot[];
};

export type LotDto = {
  id: number;
  type_room?: string;
  status?: string;
  plan?: string;
  balcony?: number | null;
  view_sea?: string;
  view_mountain?: string;
  num_apart?: string;
  cnt_apart?: string;
  area_all?: number;
  interior?: string;
  price_all?: number;
  price_m?: number;
  floor_name?: string | null;
  colspan?: number;
  rowspan?: number;
  child?: number;
  parent?: number;
  disabled: number;
  step?: number;
  type: 'flat' | 'number' | 'head';
  poligon_id?: number;
  date_update?: string;
  number_id?: number;
  row: number;
  view_action?: string;
  plan_id?: number;
  head_id?: number;
  head_name?: string;
  floors?: string;
  date_sold?: string | null;
  total_area?: string;
  by_assignment?: number;
  view_type?: string;
  kitchen_area?: number | null;
  is_panoramic_glazing?: number;
  is_commission_sales_department?: number;
  commission_lot?: number | null;
  commission_percentage_lot?: number | null;
  commission_m2?: number | null;
  commission_note?: string | null;
  registrations?: {
    id: number;
    name: string;
  }[];
  checkout2: number[] | null;

  // buyingOptions
  ipoteka: boolean | null;
  military_ipoteka: boolean | null;
  mat_capital: boolean | null;
  cash: boolean | null;
  taxes: boolean | null;
  installments: boolean | null;
  installments_info: string | null;
  is_ipoteka_family: boolean | null;
  is_ipoteka_state_support: boolean | null;
  is_ipoteka_it: boolean | null;
  is_ipoteka_tranche: boolean | null;
  is_ipoteka_subsidized: boolean | null;
  is_ipoteka_preferential: boolean | null;
};

export type NewLotDto = Omit<LotDto, 'id' | 'head_id'>;
export type NewLot = Omit<Lot, 'id'>;

export type CreateChessDto = {
  section_id: number;
  chess: NewLotDto[];
};

export type ChessDto = {
  id: number;
  name: string;
  floor_amount: number;
  riser_amount: number;
  pavilion_id: number;
  create_date: string;
  update_date: string;
  manager_update?: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
  chess: LotDto[];
};

export type LotPosition = {
  floor: number;
  riser: number;
};

export enum NumberingFormatType {
  number = 'number',
  numberLetterNumber = 'numberLetterNumber',
  letterNumber = 'letterNumber',
  numberNumber = 'numberNumber',
  numberSlashNumber = 'numberSlashNumber',
  numberNumberLetter = 'numberNumberLetter',
  letterNumberNumber = 'letterNumberNumber',
  numberLetter = 'numberLetter',
  numberPointNumber = 'numberPointNumber',
}

export type LotDataEqualsMap = {
  [K in Exclude<
    keyof Lot,
    | 'id'
    | 'positions'
    | 'disabled'
    | 'entranceId'
    | 'headId'
    | 'row'
    | 'colspan'
    | 'rowspan'
    | 'step'
  >]: boolean;
};
