import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { useTranslation } from 'react-i18next';
import { Autocomplete, debounce, Typography } from '@mui/material';
import { appendAsterisk } from 'shared/helpers/appendAsterisk';
import { PaletteColor } from 'shared/theme/colors';
import { Col } from './Col';
import { StyledTextField } from './StyledTextField';

export const API_KEY = '1df3cd36-78ca-4ff8-be76-d4a72252e228';

interface IFixedGeoObject extends ymaps.IGeoObject {
  getAddressLine(): string;

  getAdministrativeAreas(): ReadonlyArray<string>;

  getCountry(): string | null;

  getCountryCode(): string | null;

  getLocalities(): ReadonlyArray<string>;

  getPremise(): string | null;

  getPremiseNumber(): string | null;

  getThoroughfare(): string | null;
}
interface IFixedGeometry extends ymaps.IGeometry {
  getCoordinates(): [lat: number, lng: number];
}

const MIN_LENGTH_FOR_SEARCH = 3;
type MapInputProps = {
  mapWidth?: string | number;
  mapHeight?: string | number;
  defaultCoords?: [lat: number, lng: number];
  label?: string;
  inputLabel?: string;
  onChange?: (
    address: string,
    latitude: number | null,
    longitude: number | null
  ) => void;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
};

export const MapInput: FC<MapInputProps> = ({
  mapWidth,
  mapHeight,
  defaultCoords,
  label,
  inputLabel,
  onChange,
  error,
  required,
  disabled,
}) => {
  const { t } = useTranslation();
  const placemarkRef = useRef<ymaps.Placemark>();
  const mapRef = useRef<ymaps.Map>();

  const [yMapsApi, setYMapsApi] = useState<YMapsApi | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<IFixedGeoObject | null>(null);
  const [options, setOptions] = useState<readonly IFixedGeoObject[]>([]);
  const [isDefaultValueInited, setIsDefaultValueInited] = useState(false);

  const needInitDefaultValue = defaultCoords && !isDefaultValueInited;

  const getOptionLabel = (option: IFixedGeoObject) => {
    return option.getAddressLine();
  };

  const updateMapCenter = useCallback(
    (item: IFixedGeoObject) => {
      if (yMapsApi && mapRef.current) {
        const bounds = item.properties.get('boundedBy', {}) as number[][];
        // Рассчитываем видимую область для текущего положения пользователя.
        const mapState = yMapsApi.util.bounds.getCenterAndZoom(
          bounds,
          mapRef.current.container.getSize()
        );
        mapRef.current.setCenter(
          mapState.center as unknown as number[],
          mapState.zoom
        );
      }
    },
    [yMapsApi]
  );

  const updatePlacemark = useCallback(
    (item: IFixedGeoObject, coords?: number[]) => {
      if (yMapsApi && placemarkRef?.current?.geometry) {
        const shortAddress = [
          item.getThoroughfare(),
          item.getPremiseNumber(),
          item.getPremise(),
        ].join(' ');

        placemarkRef.current.properties.set('iconCaption', shortAddress);
      }
    },
    [yMapsApi]
  );

  useEffect(() => {
    if (value) {
      updateMapCenter(value);
      updatePlacemark(value);
    }
  }, [updateMapCenter, updatePlacemark, value]);

  const search = useMemo(
    () =>
      debounce(
        (
          input: string | number[],
          callback: (results: ymaps.IGeocodeResult) => void
        ) => {
          const isStringInput = typeof input === 'string';
          const options: ymaps.IGeocodeOptions | undefined = {
            results: isStringInput ? 6 : 1,
            boundedBy: isStringInput ? mapRef?.current?.getBounds() : undefined,
          };

          yMapsApi
            ?.geocode(input, options)
            .then((res) => callback(res))
            .catch(() => {
              console.error('geocode catch');
            });
        },
        400
      ),
    [yMapsApi, mapRef]
  );

  const selectFirstFound = useCallback(
    (result: ymaps.IGeocodeResult, coords: number[] | null = null) => {
      if (coords) {
        const lat = coords[0];
        const lng = coords[1];

        if (result.geoObjects.getLength()) {
          const newValue = result.geoObjects.get(0) as IFixedGeoObject;
          const address = newValue.getAddressLine();

          placemarkRef?.current?.geometry?.setCoordinates(coords);

          setOptions([]);
          setValue(newValue);
          setInputValue(address);

          if (onChange) {
            onChange(address, lat, lng);
          }
        } else {
          yMapsApi?.geocode(coords).then((res) => {
            const nearestGeoObject = res.geoObjects.get(0) as IFixedGeoObject;
            const nearestAddress = nearestGeoObject
              .getAdministrativeAreas()
              .join(', ');

            placemarkRef?.current?.geometry?.setCoordinates(coords);

            setOptions([]);
            setValue(nearestGeoObject);
            setInputValue(nearestAddress);

            if (onChange) {
              onChange(nearestAddress, lat, lng);
            }
          });
        }
      } else {
        setOptions([]);
        setValue(null);
        setInputValue('');
        if (onChange) {
          onChange('', null, null);
        }
      }

      if (needInitDefaultValue) {
        setIsDefaultValueInited(true);
      }
    },
    [needInitDefaultValue, onChange, yMapsApi]
  );

  useEffect(() => {
    if (value) {
      const coords = placemarkRef?.current?.geometry?.getCoordinates();
      if (coords) {
        updatePlacemark(value, coords);
      }
    }
  }, [updatePlacemark, value]);

  useEffect(() => {
    if (yMapsApi && needInitDefaultValue && defaultCoords) {
      search(defaultCoords, (result) => {
        selectFirstFound(result, defaultCoords);
      });
    }
  }, [defaultCoords, needInitDefaultValue, search, selectFirstFound, yMapsApi]);

  useEffect(() => {
    let active = true;

    if (inputValue.length <= MIN_LENGTH_FOR_SEARCH || !yMapsApi) {
      setOptions([]);
      return;
    }

    const isCoordinates = inputValue.match(
      /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?((1[0-7]\d)|([1-9]?\d))(\.\d+)?$/
    );

    if (isCoordinates) {
      const coords = inputValue.split(',').map(Number);
      search(coords, (res) => {
        if (active) {
          selectFirstFound(res);
        }
      });
    } else {
      search(inputValue, (res) => {
        if (active) {
          let newOptions: readonly IFixedGeoObject[] = [];

          if (value) {
            newOptions = [value];
          }

          if (res.geoObjects.getLength()) {
            newOptions = [
              ...newOptions,
              ...(res.geoObjects.toArray() as IFixedGeoObject[]),
            ];
          }
          setOptions(newOptions);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, yMapsApi, search, selectFirstFound]);

  const handleAutocompleteChange = (
    event: SyntheticEvent | null,
    newValue: IFixedGeoObject | null
  ) => {
    setValue(newValue);
    if (onChange && newValue) {
      const address = newValue.getAddressLine();
      const coords = newValue.geometry
        ? (newValue.geometry as IFixedGeometry).getCoordinates()
        : null;

      if (coords && placemarkRef?.current?.geometry) {
        placemarkRef.current.geometry.setCoordinates(coords);
        mapRef.current?.setCenter(coords, 7);
      }

      const lat = coords?.[0] ?? null;
      const lng = coords?.[1] ?? null;
      onChange(address, lat, lng);
    } else if (onChange && !newValue) {
      setInputValue('');
      setValue(null);

      if (placemarkRef?.current?.geometry) {
        placemarkRef.current.geometry.setCoordinates([0, 0]);
        mapRef.current?.setCenter([43.585472, 39.723098]);
      }
      onChange('', null, null);
    }
  };

  const handleMapLoad = (e: YMapsApi) => {
    setYMapsApi(e);
  };

  return (
    <YMaps
      query={{
        lang: 'ru_RU',
        load: 'Map,Placemark,geocode,util.bounds,control.ZoomControl',
        apikey: API_KEY,
      }}
    >
      <Col spacing={4}>
        <Col spacing={9}>
          <Col spacing={5}>
            <Typography variant={'paragraphM'}>
              {label ? label : t('ui.mapInput.defaultLabel')}
            </Typography>
            <Map
              instanceRef={mapRef}
              options={{
                suppressMapOpenBlock: true,
              }}
              defaultState={{
                center: [43.585472, 39.723098],
                zoom: 11,
                controls: ['zoomControl'],
              }}
              onLoad={handleMapLoad}
              width={mapWidth || 600}
              height={mapHeight || 382}
              sx={{
                border: `1px solid ${PaletteColor.Error}`,
              }}
              onClick={(event: ymaps.MapEvent) => {
                if (!disabled) {
                  const coords = event.get('coords') as number[] | null;
                  if (coords) {
                    placemarkRef?.current?.geometry?.setCoordinates(coords);
                    search(coords, (result) =>
                      selectFirstFound(result, coords)
                    );
                  }
                }
              }}
            >
              <Placemark
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                instanceRef={placemarkRef}
                onDragEnd={(event: ymaps.MapEvent) => {
                  if (!disabled) {
                    const coords = event
                      .get('target')
                      .geometry.getCoordinates();
                    if (coords) {
                      search(coords, (result) =>
                        selectFirstFound(result, coords)
                      );
                    }
                  }
                }}
                options={{
                  iconImageSize: [30, 30],
                  draggable: !disabled,
                  preset: 'islands#redDotIconWithCaption',
                  hideIconOnBalloonOpen: false,
                  openEmptyHint: false,
                }}
              />
            </Map>
          </Col>

          <Autocomplete
            autoComplete
            includeInputInList
            filterSelectedOptions
            filterOptions={(x) => x}
            value={value}
            sx={{
              border: error ? `1px solid ${PaletteColor.Error}` : 'none',
              borderRadius: '8px',
            }}
            options={options}
            onChange={handleAutocompleteChange}
            inputValue={inputValue}
            onInputChange={(e, newInputValue) => {
              if (!disabled) {
                setInputValue(newInputValue);
              }
            }}
            disableClearable={disabled}
            disabled={disabled}
            getOptionLabel={(option) => getOptionLabel(option)}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                multiline
                label={
                  inputLabel
                    ? inputLabel
                    : appendAsterisk(
                        t('ui.mapInput.defaultInputLabel'),
                        required,
                        !!error
                      )
                }
                maxLength={100}
                showCharacterHintAtRemaining={10}
                mapTextField
                disabled={disabled}
                disabledAdornment={disabled}
              />
            )}
          />
        </Col>
        {error && (
          <Typography variant="paragraphS" color={PaletteColor.Error}>
            {t('ui.mapInput.errorNoAddress')}
          </Typography>
        )}
      </Col>
    </YMaps>
  );
};
