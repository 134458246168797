import { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Typography } from '@mui/material';
import { UserRole } from 'entities/profile';
import {
  createDeveloperUserZod,
  DeveloperUser,
  useUpdateDeveloperUserMutation,
  useCreateDeveloperUserMutation,
} from 'entities/user';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { NumericField } from 'shared/ui/NumericField';
import { PhoneField } from 'shared/ui/PhoneField';
import { Row } from 'shared/ui/Row';
import { StyledTextField } from 'shared/ui/StyledTextField';

type CreateDeveloperUserCardProps = {
  developerUser?: DeveloperUser;
  developerId?: number;
  onSaved?: () => void;
  onCancelClick?: () => void;
};

export const CreateDeveloperUserCard: FC<CreateDeveloperUserCardProps> = ({
  developerUser,
  developerId,
  onSaved,
  onCancelClick,
}) => {
  const { t } = useTranslation();
  const [createDeveloperUser, { isLoading: isDeveloperUserCreating }] =
    useCreateDeveloperUserMutation();
  const [updateDeveloperUser, { isLoading: isDeveloperUserUpdating }] =
    useUpdateDeveloperUserMutation();
  const { showSnackbar } = useSnackbar();

  const form = useForm<DeveloperUser>({
    resolver: zodResolver(createDeveloperUserZod),
    mode: 'all',
    defaultValues: {
      firstName: developerUser?.firstName || '',
      lastName: developerUser?.lastName || '',
      position: developerUser?.position || '',
      telegram: developerUser?.telegram || '',
      whatsapp: developerUser?.whatsapp || '',
      phone: developerUser?.phone || '',
      email: developerUser?.email || '',
      id: developerUser?.id,
      developerId: developerUser?.developerId || developerId,
      roleId: developerUser?.roleId || UserRole.developerEmployee,
    },
  });

  const handleSaveClick = async (userData: DeveloperUser) => {
    try {
      if (!developerUser) {
        await createDeveloperUser({ ...userData }).unwrap();
      } else {
        await updateDeveloperUser({
          ...developerUser,
          ...userData,
        }).unwrap();
      }

      onSaved?.();
    } catch (error) {
      console.error(error);
      switch (error) {
        case 'ERROR_PHONE_ALREADY_USED':
          form.setError('phone', {
            message: t('user.form.error.phoneAlreadyUsed'),
          });
          break;
        case 'ERROR_EMAIL_ALREADY_USED':
          form.setError('email', {
            message: t('user.form.error.emailAlreadyUsed'),
          });
          break;
        default:
          showSnackbar();
          break;
      }
    }
  };

  const formErrors = form.formState.errors.root?.message
    ? [form.formState.errors.root.message]
    : [];

  return (
    <CardLayout
      footer={{
        onOkClick: form.handleSubmit(handleSaveClick),
        onCancelClick: onCancelClick,
        okLabel: t('ui.common.save'),
        cancelLabel: t('ui.common.cancel'),
        okButtonColor: 'secondary',
        okButtonLoading: isDeveloperUserCreating || isDeveloperUserUpdating,
      }}
      maxWidth={606}
      variant="secondary"
      errors={formErrors}
    >
      <FormProvider {...form}>
        <Col spacing={8}>
          <Col spacing={8}>
            <Typography variant={'headerXS'}>
              {developerUser?.id
                ? t('user.form.title.editEmployee')
                : t('user.form.title.addEmployee')}
            </Typography>
            <Row spacing={5} equalsChildrenWidth>
              <StyledTextField
                name={'firstName'}
                label={t('ui.common.firstName')}
                required
                maxLength={60}
                showCharacterHintAtRemaining={10}
              />
              <StyledTextField
                name={'lastName'}
                label={t('ui.common.lastName')}
                maxLength={20}
                showCharacterHintAtRemaining={10}
              />
            </Row>
          </Col>
          <StyledTextField
            name={'position'}
            label={t('user.form.label.position')}
            required
            maxLength={30}
            showCharacterHintAtRemaining={10}
          />

          <Row spacing={5} equalsChildrenWidth>
            <PhoneField name={'phone'} required />

            <StyledTextField
              name={'email'}
              label={t('ui.common.email')}
              required
              type="email"
              maxLength={50}
              showCharacterHintAtRemaining={10}
            />
          </Row>

          <Row spacing={5} equalsChildrenWidth>
            <Col spacing={2}>
              <StyledTextField
                name={'telegram'}
                label={t('ui.common.telegram')}
                maxLength={32}
                showCharacterHintAtRemaining={7}
              />
              <FormHelperText>{t('user.form.hint.telegram')}</FormHelperText>
            </Col>
            <Col spacing={2}>
              <NumericField
                name={'whatsapp'}
                label={t('ui.common.whatsapp')}
                valueIsNumericString
                maxLength={15}
                showCharacterHintAtRemaining={5}
                allowNegative={false}
              />
              <FormHelperText>{t('user.form.hint.whatsapp')}</FormHelperText>
            </Col>
          </Row>
        </Col>
      </FormProvider>
    </CardLayout>
  );
};
