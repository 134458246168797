import { ChangeEvent, FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { NumericFormatProps } from 'react-number-format/types/types';
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { getCharacterCountHint } from 'shared/helpers/getCharacterCountHint';
import { PaletteColor } from 'shared/theme/colors';
import { ClearTextFieldSvgIcon } from './Icons';

type CustomInputProps = TextFieldProps & {
  showCharacterHintAtRemaining?: number;
  disabledAdornment?: boolean;
  maxLength?: number;
  mapTextField?: boolean;
  innerRef?: (inputElement: HTMLElement) => void;
  errorMessage?: string;
};

const CustomInput = ({
  errorMessage,
  showCharacterHintAtRemaining,
  ...props
}: CustomInputProps) => {
  const { t } = useTranslation();

  const getHelperText = (value: string) => {
    if (props.helperText) {
      return props.helperText;
    }

    let helperText = '';
    if (props.maxLength) {
      if (showCharacterHintAtRemaining && value) {
        const remaining = props.maxLength - value.length;
        if (remaining > showCharacterHintAtRemaining) {
          return helperText;
        }
        helperText = getCharacterCountHint(
          props.maxLength,
          showCharacterHintAtRemaining,
          value.replaceAll(' ', '').length,
          t
        );
      }
    }

    return helperText;
  };

  return (
    <>
      <TextField
        {...props}
        fullWidth
        autoComplete="off"
        variant={props.variant}
        error={Boolean(props.error)}
        inputProps={{
          ...props.inputProps,
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment: !props.disabledAdornment && !!props.value && (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  // props.onChange('');
                  // TODO: сделать красивше
                  if (props.onChange) {
                    const e = {
                      target: {
                        value: '',
                        focus: () => null,
                      },
                    };

                    props.onChange(
                      e as unknown as ChangeEvent<HTMLInputElement>
                    );
                  }
                }}
                sx={
                  props.mapTextField
                    ? { position: 'absolute', top: '5px', right: '10px' }
                    : {}
                }
              >
                <ClearTextFieldSvgIcon
                  fontSize="inherit"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            border: props.error ? `1px solid ${PaletteColor.Error}` : 'none',
            '& .MuiButtonBase-root': {
              width: '20px',
              height: '20px',
            },
            ...props.InputProps?.sx,
          },
          ...props.InputProps,
        }}
        InputLabelProps={{
          sx: {
            '&.Mui-focused:not(.Mui-filled)': {
              fontSize: '18px',
              fontWeight: 400,
            },
            '&.Mui-filled': {
              fontSize: '18px',
              fontWeight: 400,
            },
          },
        }}
        sx={{
          '.MuiFormLabel-filled': {
            fontSize: '18px',
            fontWeight: 400,
          },
          '.MuiFormLabel-focused': {
            fontSize: '18px',
            fontWeight: 400,
          },
          ...props.sx,
        }}
      />
      {props.error ? (
        errorMessage && (
          <FormHelperText sx={{ color: PaletteColor.Error }}>
            {t(errorMessage)}
          </FormHelperText>
        )
      ) : (
        <FormHelperText>{getHelperText(props.value as string)}</FormHelperText>
      )}
    </>
  );
};

type NumericFieldProps = Omit<NumericFormatProps, 'value'> & CustomInputProps;

export const NumericField: FC<NumericFieldProps> = (props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name || ''}
      control={control}
      render={({
        field: { ref, onChange, ...rest },
        fieldState: { error },
      }) => (
        <NumericFormat
          {...props}
          {...rest}
          error={Boolean(error)}
          errorMessage={error?.message}
          variant="filled"
          isAllowed={(v) =>
            !!props.maxLength && v.value.length <= props.maxLength
          }
          onValueChange={(v, e) => {
            if (e.event)
              props.onChange?.(
                e.event as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              );
            onChange(v.value);

            props.onValueChange?.(v, e);
          }}
          customInput={CustomInput}
        />
      )}
    />
  );
};
