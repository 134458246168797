import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { skipToken } from '@reduxjs/toolkit/query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAgenciesQuery, useGetAgencyQuery } from 'entities/agency';
import { ServerErrorMessage } from 'entities/developer';
import {
  CreateAgencyUser,
  AgencyUserEditForm,
  createAgencyUserZod,
  useCreateAgencyUserMutation,
} from 'entities/user';
import { getRoute } from 'shared/const';
import { useDebounce } from 'shared/hooks/useDebounce';
import { SelectOption } from 'shared/types';
import { CardLayout } from 'shared/ui/CardLayout';
import { ResultCard } from 'shared/ui/ResultCard';

export const CreateAgencyUserCard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { agencyId } = useParams<{ agencyId: string }>();
  const [isShowAdditionalData, setIsShowAdditionalData] =
    useState<boolean>(false);
  const [agencyOptions, setAgencyOptions] = useState<SelectOption[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const debouncedSearchText = useDebounce(searchText);
  const [isAgencyUserCreatingError, setIsAgencyUserCreatingError] =
    useState(false);

  const [
    createAgencyUser,
    {
      isLoading: isAgencyUserLoading,
      isSuccess: isAgencyUserSuccessfullyCreated,
    },
  ] = useCreateAgencyUserMutation();
  const { data: agency, isLoading: isAgencyLoading } = useGetAgencyQuery(
    agencyId ?? skipToken
  );
  const { data: searchAgenciesData, isFetching: isAgenciesSearching } =
    useGetAgenciesQuery({
      limit: 20,
      offset: 0,
      search: debouncedSearchText,
    });

  const form = useForm<CreateAgencyUser>({
    resolver: zodResolver(createAgencyUserZod),
    mode: 'onSubmit',
    defaultValues: {
      phone: '',
      firstName: '',
      lastName: '',
      email: '',
      position: '',
      tariff: null,
      isAdmin: false,
      agencyId: Number(agencyId),
      facebook: '',
      instagram: '',
      ok: '',
      vk: '',
      youtube: '',
      invitedBy: '',
      telegram: '',
      whatsapp: '',
    },
  });

  const handleCreateAgencyUser = async (userData: CreateAgencyUser) => {
    try {
      await createAgencyUser(userData).unwrap();
    } catch (error) {
      console.error(error);
      switch (error) {
        case 'ERROR_EMAIL_ALREADY_USED':
          form.setError('email', {
            message: ServerErrorMessage.ownerEmailInUse,
          });
          break;
        case 'ERROR_PHONE_ALREADY_USED':
          form.setError('phone', {
            message: ServerErrorMessage.ownerPhoneInUse,
          });
          break;
        default:
          setIsAgencyUserCreatingError(true);
          break;
      }
    }
  };

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === 'agencyId') {
        if (!value.agencyId) {
          form.resetField('isAdmin');
        } else {
          form.resetField('tariff');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  useEffect(() => {
    if (searchAgenciesData) {
      const newOptions = searchAgenciesData.items.map((agency) => ({
        label: agency.name,
        value: agency.id,
      }));
      if (newOptions.some((option) => option.value === Number(agencyId))) {
        setAgencyOptions(newOptions);
      } else if (agency) {
        setAgencyOptions([
          { label: agency?.name, value: Number(agencyId) },
          ...newOptions,
        ]);
      }
    }
  }, [agency, agencyId, searchAgenciesData]);

  if (isAgencyUserSuccessfullyCreated || isAgencyUserCreatingError) {
    return (
      <ResultCard
        isSuccess={isAgencyUserSuccessfullyCreated}
        successProps={{
          title: t('user.resultCard.success.title'),
          subtitle: t('user.resultCard.success.subtitle'),
          primaryButton: {
            navigateTo: agencyId
              ? getRoute.AgencyUsers(agencyId)
              : getRoute.Main(),
          },
        }}
        errorProps={{
          title: t('user.resultCard.error.title'),
          primaryButton: {
            label: t('ui.common.return'),
            navigateTo: agencyId
              ? getRoute.AgencyUsers(agencyId)
              : getRoute.Main(),
          },
        }}
      />
    );
  }

  return (
    <CardLayout
      header={{
        title: t('user.tab.button.addUser'),
        subtitle: t('user.create.card.subtitle'),
      }}
      footer={{
        onOkClick: form.handleSubmit(handleCreateAgencyUser),
        onCancelClick: () => navigate(getRoute.Users()),
        okLabel: t('ui.common.create'),
        okButtonColor: 'primary',
        cancelLabel: t('ui.common.close'),
        okButtonLoading: isAgencyUserLoading,
      }}
      maxWidth={648}
      marginAuto
      paddingY={24}
    >
      <FormProvider {...form}>
        <AgencyUserEditForm
          isShowAdditionalData={isShowAdditionalData}
          onToggleAdditionalData={setIsShowAdditionalData}
          agencyOptions={agencyOptions}
          onAgencySearchChange={setSearchText}
          isAgenciesSearching={isAgenciesSearching}
        />
      </FormProvider>
    </CardLayout>
  );
};
