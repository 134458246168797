import { Profile, ProfileDto, UserRole } from '../model/types/profileSchema';

export const mapUserFromDto = ({
  id,
  first_name,
  last_name,
  role_id,
  email,
  token,
  is_first_login,
  developer_id,
}: ProfileDto): Profile => ({
  id,
  firstName: first_name,
  lastName: last_name,
  roleId: role_id,
  email,
  token,
  isFirstLogin: is_first_login,
  developerId: developer_id || undefined,
  isNedvexUserOrAdmin:
    role_id === UserRole.nedvexUser || role_id === UserRole.admin,
  isNedvexUserOrAdminOrApiExporter:
    role_id === UserRole.nedvexUser ||
    role_id === UserRole.admin ||
    role_id === UserRole.apiExporter,
});
